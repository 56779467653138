import { debounce } from "../../../../helpers";

export const HEART_RATE_COLOR_RGBA = "rgba(255, 92, 112, 1)";
export const OXYGEN_SATURATION_COLOR_RGBA = "rgba(100, 202, 241, 1)";

export const configPulseOximeter = (data) => {
  return {
    type: "line",
    data: data,
    plugins: [
      {
        beforeRender: (x) => {
          setupLineColors(x);
        },
      },
      // {
      //   afterUpdate: (chart) => {
      //     offsetSaturation(chart); // TODO: remove, as it is useless
      //   }
      // }
    ],
    options: pulseOximeterOptions(data.datasets),
  };
};

export const configEffort = (data) => {
  return {
    type: "line",
    data: data,
    options: effortOptions(data.datasets[0].data),
  };
};

export const generatePulseOximeterData = (heartRateLabel, saturationLabel, heartRateData, saturationData) => {
  const labels = heartRateData.map((el) => el.x);
  return {
    labels, // these are used in the ticks callback as values
    datasets: [
      {
        label: heartRateLabel,
        data: heartRateData,
        borderWidth: 2,
        xAxisID: 'x-axis-heartrate',
        yAxisID: "y-axis-heartrate",
      },
      {
        label: saturationLabel,
        data: saturationData,
        borderWidth: 2,
        xAxisID: 'x-axis-saturation', // hidden in scales option
        yAxisID: "y-axis-saturation",
      },
    ],
  };
};

export const generateEffortData = (effortData) => {
  // console.log(effortData);
  return {
    labels: effortData.map((d) => d.x),
    datasets: [
      {
        data: effortData,
        borderWidth: 2,
        xAxisID: 'x-axis-effort',
        yAxisID: 'y-axis-effort',
      },
    ],
  };
};

export const offsetSaturation = (chart) => {
  // We get the dataset and set the offset here
  var dataset = chart.config.data.datasets[1];
  var offset = 0;

  // For every data in the dataset ...
  for (var i = 0; i < dataset._meta[2]?.data.length; i++) {
    // We get the model linked to this data
    var model = dataset._meta[2]?.data[i]._model;

    // And add the offset to the `y` property
    model.y += offset;
    // .. and also to these two properties
    // to make the bezier curve fits the new graph
    model.controlPointNextX += offset;
    model.controlPointPreviousX += offset;
  }
};

export const setupLineColors = (x) => {
  const c = x.chart;
  const dataset = x.data.datasets[0];
  const heartRateGradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);
  heartRateGradientFill.addColorStop(1, "rgba(97, 41, 48, 1)"); // bottom
  heartRateGradientFill.addColorStop(0.61, HEART_RATE_COLOR_RGBA);
  const saturationGradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height);
  saturationGradientFill.addColorStop(1, OXYGEN_SATURATION_COLOR_RGBA);
  saturationGradientFill.addColorStop(0.61, OXYGEN_SATURATION_COLOR_RGBA);
  const heartRateModel =
    x.data.datasets[0]._meta[Object.keys(dataset._meta)[0]]?.dataset._model;
  const saturationModel =
    x.data.datasets[1]._meta[Object.keys(dataset._meta)[0]]?.dataset._model;

  heartRateModel.borderColor = heartRateGradientFill;
  saturationModel.borderColor = saturationGradientFill;

};

export const effortOptions = (effortData) => {
  // build annotations for drawing horizontal lines at specific ordinates
  const valuesForAxes = {
    'x-axis-effort': effortData.map((point) => point.x)
  };
  const annotations = Object.entries(valuesForAxes).map(([scaleID, values]) => {
    return values.map((value, index) => {

      // Multiplier used to adjust the first and the last labels
      let labelAdjustMultiplier = 0;
      if (index == 0) labelAdjustMultiplier = -1;
      if (index == values.length - 1) labelAdjustMultiplier = 1;

      const labelsEnabled = values.length < 10 // to avoid overlapping labels

      return {
        type: "line",
        id: `${value}-line-${index}`,
        mode: "vertical",
        scaleID: scaleID,
        value: value,
        borderColor: "rgba(79,94,112,0.4)",
        borderWidth: 1,
        label: {
          backgroundColor: 'rgba(0,0,0,0)',
          fontFamily: "Mont",
          fontStyle: "bold",
          fontSize: 70,
          fontColor: "rgba(79,94,112,0.3)",
          enabled: labelsEnabled,
          position: 'bottom',
          content: effortData.find((point) => point.x == value).y,
          xAdjust: 20 * labelAdjustMultiplier,
          yPadding: -6
        }
      };
    })
  }).flat();

  const xMaxForTicks = Math.max(...effortData.map((point) => point.x))

  return {
    annotation: {
      drawTime: "afterDatasetsDraw",
      events: ["click"],
      annotations: annotations,
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    elements: {
      line: {
        borderColor: 'rgba(255, 209, 0, 1)',
        tension: 0.1,
      },
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        left: 65,
        right: 65
      }
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        // see the other tooltips callback for t, d
        title: (t, d) => `${Math.round(d.labels[t[0].index] / 60)} min.`,
        label: (t, _d) => `Sforzo percepito: ${t.yLabel}`,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          id: 'x-axis-effort',
          gridLines: {
            display: true,
            drawTicks: true,
            tickMarkLength: 3,
            drawOnChartArea: false,
          },
          ticks: {
            display: true,
            beginAtZero: true,
            stepSize: 2,
            suggestedMax: xMaxForTicks,
            max: xMaxForTicks,
            callback: function (value, index, values) {
              // Convert x-value to minutes and seconds if needed
              const minutes = Math.floor(value / 60);
              const seconds = value % 60;
              return minutes > 0 ? `${minutes} min` : `${seconds} sec`;
            },
          },
        },
      ],
      yAxes: [
        {
          id: 'y-axis-effort',
          gridLines: {
            display: true,
            drawTicks: true,
            tickMarkLength: 3,
          },
          display: true,
          ticks: {
            suggestedMax: 10,
            beginAtZero: true, // minimum value will be 0.
            stepSize: 2,
            display: true,
            callback: () => "",
          },
        },
      ],
    },
  };
}

export const pulseOximeterOptions = (datasets) => {
  const labels = datasets.map((ds) => ds.label)
  const [heartRateValues, oxygenSaturationValues] = datasets.map((ds) => ds.data.map((point) => point.y))

  const heartRateMax = Math.max(...heartRateValues);
  const heartRateMin = Math.min(...heartRateValues);
  const heartRateAvg = closestToAvg(heartRateValues);
  const heartRateTicksPositions = [
    // heartRateMax,
    heartRateAvg,
    // heartRateMin
  ];

  const oxygenSaturationMax = Math.max(...oxygenSaturationValues);
  const oxygenSaturationMin = Math.min(...oxygenSaturationValues);
  const oxygenSaturationAvg = closestToAvg(oxygenSaturationValues);
  const oxygenSaturationSafetyThreshold = 90; // used for displaying in the chart
  const oxygenSaturationTickPositions = [
    // oxygenSaturationMax,
    // oxygenSaturationAvg,
    // oxygenSaturationMin
    oxygenSaturationSafetyThreshold,
  ];

  const samplesPerSecond = 0.5; // one sample every 2 sec.
  const samplesPerMinute = 60 * samplesPerSecond; // 30 samples per minute
  const xMaxForTicks = Math.max(...datasets[0].data.map((point) => point.x));
  const xAxisSeconds = [...Array(xMaxForTicks).keys()];
  const numMinutes = Math.round(Math.max(...xAxisSeconds) / 60);

  // show x axis ticks every 2 or 5 minutes leads to strange behaviour of x-axis ticks
  // so we use 1 and let the library decide which ticks to show
  const xAxisTickStepSize = 1;

  const arrayRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_value, index) => start + index * step
    );

  // array of positions onto which the tiks are drawn. The last element is the max, so it is always drawn
  const xAxisTicksPositions = arrayRange(0, 60 * numMinutes, 60 * xAxisTickStepSize).concat([60 * numMinutes]);
  const maxTicksLimit = Math.round(numMinutes / xAxisTickStepSize);

  // build annotations for drawing horizontal and vertical lines 
  const valuesForAxes = {
    'y-axis-heartrate': heartRateTicksPositions,
    'y-axis-saturation': oxygenSaturationTickPositions,
    'x-axis-heartrate': xAxisTicksPositions
  };

  // console.log(datasets[0].data.map((point) => point.x));
  const annotations = Object.entries(valuesForAxes).map(([scaleID, values]) => {
    return values.map((value, index) => {
      return {
        type: "line",
        id: `${value}-line-${index}`,
        mode: scaleID.startsWith('x') ? "vertical" : "horizontal",
        scaleID: scaleID,
        value: value,
        borderColor: "rgba(79,94,112,0.6)",
        borderWidth: 1,
      };
    })
  }).flat();

  return {
    annotation: {
      drawTime: "afterDatasetsDraw",
      events: ["click"],
      annotations: annotations,
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    elements: {
      borderWidth: 0,
      line: {
        tension: 0.2,
      },
      point: {
        radius: 0,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (t, d) {
          // from the offical docs
          // t: tooltipItem
          // d: data
          return (
            d.datasets[t.datasetIndex].label +
            ": " +
            d.datasets[t.datasetIndex].data[t.index].y +
            (t.datasetIndex == 0 ? "bpm" : "%")
          );
        },
        title: (t, d) => {
          // t: tooltipItem[]
          // console.log('d', d);
          // Uncomment the previous line to see what d is and what we take out from it.
          // Here we use the x, that we consider as the timestamp in seconds
          const calculatedSecond = d.datasets[0].data[t[0].index]?.x || 0;
          // console.log('calculatedSecond', calculatedSecond);
          return `${Math.round(calculatedSecond / 60)} min.`; // this is the minute instead
        },
      },
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          id: 'x-axis-saturation',
          display: false,
          gridLines: {
            display: false
          },
          ticks: {
            display: false
          }
        },
        {
          display: true,
          id: 'x-axis-heartrate',
          gridLines: {
            display: false,
            drawTicks: true,
            tickMarkLength: 5,
            drawOnChartArea: true,
          },
          ticks: {
            display: true,
            beginAtZero: true,

            // Explicitly use xAxisTicksPositions to determine where ticks are drawn
            callback: function (value, index, values) {
              let samplingRate = 1;
              const maxTime = Math.max(...datasets[0].data.map(point => point.x));
              if (maxTime <= 600) {         // Less than 10 minutes
                samplingRate = 1;           // Tick every 1 minute
              } else if (maxTime <= 1800) { // Between 10 and 30 minutes
                samplingRate = 2;           // Tick every 2 minutes
              } else {                      // More than 30 minutes
                samplingRate = 5;           // Tick every 5 minutes
              }
              // Only show ticks at the positions specified in xAxisTicksPositions
              if (sampleArray(xAxisTicksPositions, samplingRate).includes(value)) {
                const minutes = Math.floor(value / 60);  // Convert seconds to minutes
                return `${minutes} min`;  // Return formatted label in minutes
              }
              return '';  // Skip ticks that are not in xAxisTicksPositions
            },

            // Ensure no ticks are skipped
            autoSkip: false,

            // Rotate or pad the labels slightly if they still overlap (adjust padding if needed)
            maxRotation: 0,
            minRotation: 0,
            padding: 10,
          },
        },
      ],
      yAxes: [
        {
          id: 'y-axis-heartrate',
          type: 'linear',
          position: "left",
          scaleLabel: {
            display: false,
            // labelString: labels[0],
            fontColor: HEART_RATE_COLOR_RGBA,
            fontSize: 11,
          },
          gridLines: {
            display: false,
            drawTicks: false,
            tickMarkLength: 0,
            drawOnChartArea: false,
          },
          display: true,
          ticks: {
            // suggestedMax: heartRateMax + 15,
            // suggestedMin: heartRateMin - 40,
            max: heartRateMax + 15,
            min: 0,
            beginAtZero: false,
            display: true,
            fontColor: HEART_RATE_COLOR_RGBA,
            fontFamily: 'Gotham',
            // fontSize: 11,
            stepSize: 1,
            callback: function (value, _index, _values) {
              if (heartRateTicksPositions.includes(value)) return `${value} BPM`;
              return "";
            },
          },
        },
        {
          id: 'y-axis-saturation',
          type: 'logarithmic',
          position: "right",
          scaleLabel: {
            display: false,
            // labelString: labels[1],
            fontColor: OXYGEN_SATURATION_COLOR_RGBA,
            fontSize: 11,
          },
          gridLines: {
            display: false,
          },
          display: true,
          ticks: {
            // suggestedMax: oxygenSaturationMax + 70, // not usable in logarithmic
            // suggestedMin: oxygenSaturationMin - 35, // not usable in logarithmic
            max: 120,
            min: 0,
            autoSkip: false,
            beginAtZero: false, // minimum value will be 0.
            display: true,
            fontColor: OXYGEN_SATURATION_COLOR_RGBA,
            fontFamily: 'Gotham',
            // fontSize: 11,
            // stepSize: 1, // not usable in logarithmic
            callback: function (value, _index, _values) {
              // displays the label 90% just because the 'nice number algorithm' used in logarithmic axis puts this value into this callback
              // console.log(value);
              if (oxygenSaturationTickPositions.includes(value)) return `${value}%`;
              return "";
            },
          },
        },
      ],
    },
  };
};

// Return the one value in values which is closest to the average
const closestToAvg = (values) => {
  const avg = values.reduce((a, b) => a + b, 0) / values.length;
  return values.reduce((prev, curr) => Math.abs(curr - avg) < Math.abs(prev - avg) ? curr : prev);
};

export const initialsOf = (text) => {
  return text.split(' ').map(w => w[0]).join('').toUpperCase()
}

function sampleArray(arr, samplingRate) {
  const result = [arr[0]]; // Always include the first element

  // Loop through the array and take samples based on the rate
  for (let i = 1; i < arr.length - 1; i++) {
    // Since the array increments by 60 minutes, we divide by 60
    // and check if it's divisible by the sampling rate (minutes)
    if ((arr[i] / 60) % samplingRate === 0) {
      result.push(arr[i]);
    }
  }

  result.push(arr[arr.length - 1]); // Always include the last element
  return result;
}